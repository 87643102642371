import React from "react";

const ContentReasonHead = () => {
  return (
    <div className="websiteWhyHead">
      <h6>REASON</h6>
      <h2>10 Reasons to Opt-in Content Writing Services for Small Business</h2>
      <p>
        Here are the top 10 reasons to opt content writing service in Bangalore
        today that can be beneficial for your business:
      </p>
    </div>
  );
};

export default ContentReasonHead;
