import React from "react";
import "./HomeFaq.css"
const HomeFaqHead = () => {
  return (
    <div className="homeFaqHead">
      <h6>F.A.Q</h6>
      <h2>Most Trending & Popular Question</h2>
    </div>
  );
};

export default HomeFaqHead;
