import React from "react";
import "./Lp1Testimonial.css";
const Lp1TestimonialHead = () => {
  return (
    <div className="lp1TestimonialHead">
      <h6>TESTIMONIAL</h6>
      <h2>See Feedback Why Customers Love Working With Us</h2>
    </div>
  );
};

export default Lp1TestimonialHead;
