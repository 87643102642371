import React from "react";
import "./HomeFocused.css";
const HomeFocusedHead = () => {
  return (
    <div className="homeServiceHead" id="Services">
      <h2>Our SEO Services Focused On</h2>
    </div>
  );
};

export default HomeFocusedHead;
