import React from "react";
import "./Lp1Package.css";
import Lp1PackageCard from "./Lp1PackageCard";
const Lp1PackageMain = () => {
  return (
    <div className="lp1PackageMain">
      <Lp1PackageCard />
      <Lp1PackageCard />
      <Lp1PackageCard />
    </div>
  );
};

export default Lp1PackageMain;
