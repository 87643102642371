import React from "react";
import "./HomePage.css";
const HomeSqAnim = () => {
  return (
    <div className="homeSqAnim">
      <ul class="background">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );
};

export default HomeSqAnim;
