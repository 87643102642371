import React from "react";
import "./HomeTestimonial.css";
const HomeTestimonialHeader = () => {
  return (
    <div className="homeTestimonialHeader">
      <h6>TESTIMONIAL</h6>
      <h2>See Feedback Why Customers Love Working With Us</h2>
    </div>
  );
};

export default HomeTestimonialHeader;
