import React from "react";
import FooterCopy from "../../../Footer/BelowFooter/FooterCopy";
import FooterSocial from "./../../../Footer/BelowFooter/FooterSocial";
import "./Lp1Footer.css"
const Lp1Footer = () => {
  return (
    <div className="footerWrapper2" id="lp1Footer">
      <FooterCopy />
      <FooterSocial/>
    </div>
  );
};

export default Lp1Footer;
