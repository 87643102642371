import React from 'react'
import GoogleAdsPricingHead from './GoogleAdsPricingHead'
import GoogleAdsPricingMain from './GoogleAdsPricingMain'

const GoogleAdsPricing = () => {
  return (
    <div id='pricing'>
<GoogleAdsPricingHead/>
<GoogleAdsPricingMain/>
    </div>
  )
}

export default GoogleAdsPricing
