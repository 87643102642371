import React from "react";
import "./Lp1Package.css";

const Lp1PackageHead = () => {
  return (
    <div className="lp1PackageHead">
      <h6>PACKAGE</h6>
      <h2>Pricing & Plans</h2>
    </div>
  );
};

export default Lp1PackageHead;
