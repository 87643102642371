import React from 'react'
import "./WebsiteClient.css"
const WebsiteClientHead = () => {
  return (
    <div className='websiteClientHead'>
      <h6>CLIENT</h6>
      <h2><span>Success Stories:</span> Our Clients, Our Pride​
      </h2>
    </div>
  )
}

export default WebsiteClientHead
