import React from "react";
import "./../Footer.css"
const FooterCopy = () => {
  return (
    <div className="footerCopy">
      <p>© 2023 TrySEOServices.Com All Rights Reserved.</p>
    </div>
  );
};

export default FooterCopy;
