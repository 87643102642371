import React from "react";
import "./Lp1Testimonial.css";
import Lp1TestimonialCard from "./Lp1TestimonialCard";
const Lp1TestimonialMain = () => {
  return (
    <div className="lp1TestimonialMain">
      <Lp1TestimonialCard />
      <Lp1TestimonialCard />
      <Lp1TestimonialCard />
      <Lp1TestimonialCard />
    </div>
  );
};

export default Lp1TestimonialMain;
