import React from "react";
import "./Lp1Testimonial.css";
const Lp1TestimonialCard = () => {
  return (
    <div className="lp1TestimonialCard">
      <img
        src="https://themexriver.com/wp/gesto/wp-content/uploads/2023/10/gto-t3-img-2.png"
        alt=""
      />
      <div className="lp1TestimonialCardCover">
       <div className="lp1TestimonialCardCover-Content"> <h3>Our SEO toolbox</h3>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quisquam,
          veritatis? Nesciunt labore veritatis dolorem. Laborum omnis placeat
          explicabo deserunt magnam asperiores doloribus ab nemo, aspernatur
          aliquid molestias ducimus, eaque molestiae?
        </p></div>
      </div>
    </div>
  );
};

export default Lp1TestimonialCard;
